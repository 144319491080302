import { DynamicMetaTags } from "../utils/dynamicMetaTags";

// import { DetailCards } from "../components/landingPage/detailCards";
// import { Location } from "../components/landingPage/location";
// import { Footer } from "../components/navigation/footer";

export const LandingPage = () => {
  return (
    <div style={{ justifyContent: "center", display: "flex" }}>
      <DynamicMetaTags ogTitle={`Rehan Jayawickerama`} ogImage={""} />
      <div>
        {/* <Hero helloWorld={helloWorld} /> */}
        {/* <Divider>Social Media</Divider> */}
        {/* <Instagram />
        <Twitter />
        <ContactUs /> */}
        {/* <DetailCards />
        <Divider />
        <Location />
        <Footer /> */}
      </div>
    </div>
  );
};
