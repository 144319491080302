import "./App.css";
// import { useState, useEffect } from "react";
// import axios from "axios";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { NotFound } from "./utils/notFound";

import { LandingPage } from "./pages/LandingPage";
import { Qr } from "./pages/Qr";

function App() {
  // const [helloWorld, setHelloWorld] = useState("");

  // const getHelloWorld = () => {
  //   axios
  //     .get(".netlify/functions/getHelloWorld", {
  //       baseURL: "/",
  //     })
  //     .then(function (response) {
  //       setHelloWorld(response.data.data);
  //     });
  // };
  // useEffect(() => {
  //   getHelloWorld();
  // }, []);

  return (
    <Router>
      {/* <NavBar /> */}
      <Routes>
        <Route exact path="/" element={<LandingPage />} />
        <Route exact path="/qr" element={<Qr />} />
        <Route exact path="/*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
