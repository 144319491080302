import React from "react";
import { Button, Col, Row } from "antd";
import profileImage from "../images/rehan-jayawickreme.jpeg";
import {
  PhoneOutlined,
  MailOutlined,
  InstagramOutlined,
  TwitterOutlined,
  FacebookOutlined,
  ContactsOutlined,
} from "@ant-design/icons";

export const Qr = () => {
  const textRef = React.useRef(null);
  const contactInfo = `
    BEGIN:VCARD
    VERSION:3.0
    FN:Rehan Jayawickerama
    TEL:+94 77 389 4894
    EMAIL:rehanjayawickreme@gmail.com
    ORG:Your Company
    TITLE:SJB Chief Organizer for Weligama
    END:VCARD
    `;

  const downloadVCard = () => {
    const encodedUri = encodeURI(
      `data:text/vcard;charset=utf-8,${contactInfo}`
    );
    const a = document.createElement("a");
    a.href = encodedUri;
    a.download = "contact.vcf";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };
  return (
    // <div
    //   style={{
    //     display: "flex",
    //     justifyContent: "center",
    //   }}
    // >
    //   <Row gutter={[18, 18]} style={{ textAlign: "center" }}>
    //     <Col span={24}>
    //       <Avatar></Avatar>
    //       <p>Rehan Jayawickreme</p>
    //       <p>
    //         <small>SJB Chief Organiser for Weligama</small>
    //       </p>
    //       <Space>
    //         <Avatar></Avatar>
    //         <Avatar></Avatar>
    //       </Space>
    //     </Col>
    //     <Col span={24}>
    //       <ul style={{ listStyleType: "none", padding: "0px" }}>
    //         <li style={{ margin: "0px" }}>
    //           <Button>Instagram</Button>
    //         </li>
    //         <li>
    //           <Button>Facebook</Button>
    //         </li>
    //       </ul>
    //     </Col>
    //   </Row>
    // </div>
    <div className="App">
      <header className="App-header">
        <div className="imageBgGradient"></div>
        <img
          src={profileImage}
          alt="Rehan Jayawickerama"
          className="profile-picture"
        />
      </header>
      <main>
        <h1 style={{ fontSize: "20px" }}>
          <strong>Rehan Jayawickerama</strong>
        </h1>
        <p style={{ fontSize: "14px" }}>SJB Chief Organizer for Weligama</p>
        <div className="icon-container">
          <a className="icon" href="tel:+94 77 389 4894" aria-label="Call">
            <PhoneOutlined
              style={{ fontSize: "1em", color: "rgb(94, 131, 25)" }}
            />
          </a>
          <a
            className="icon"
            href="mailto:rehanjayawickreme@gmail.com"
            aria-label="Email"
          >
            <MailOutlined
              style={{ fontSize: "1em", color: "rgb(94, 131, 25)" }}
            />
          </a>
        </div>
        <Row className="button-container">
          <Col span={24}>
            <Button
              type="primary"
              shape="round"
              icon={<InstagramOutlined style={{ verticalAlign: "middle" }} />}
              size="large"
              style={{
                backgroundColor: "#E4405F",
                borderColor: "#E4405F",
                marginBottom: "10px",
                width: "200px",
              }}
              onClick={() =>
                (window.location.href =
                  "https://www.instagram.com/rehaan_jayawickreme/")
              }
            >
              Instagram
            </Button>
          </Col>
          <Col span={24}>
            <Button
              type="primary"
              shape="round"
              icon={<TwitterOutlined style={{ verticalAlign: "middle" }} />}
              size="large"
              style={{
                backgroundColor: "#1DA1F2",
                borderColor: "#1DA1F2",
                marginBottom: "10px",
                width: "200px",
              }}
              onClick={() =>
                (window.location.href = "https://x.com/RehanJayawick")
              }
            >
              Twitter
            </Button>
          </Col>
          <Col span={24}>
            <Button
              type="primary"
              shape="round"
              icon={<FacebookOutlined style={{ verticalAlign: "middle" }} />}
              size="large"
              style={{
                backgroundColor: "#1877F2",
                borderColor: "#1877F2",
                marginBottom: "10px",
                width: "200px",
              }}
              onClick={() =>
                (window.location.href =
                  "https://www.facebook.com/rehanjayawickreme")
              }
            >
              Facebook
            </Button>
            <Col span={24}>
              <Button
                disabled
                type="primary"
                shape="round"
                icon={<ContactsOutlined style={{ verticalAlign: "middle" }} />}
                size="large"
                style={{
                  width: "200px",
                  backgroundColor: "#33ccc4",
                }}
                onClick={downloadVCard}
              >
                Save Contact
              </Button>
            </Col>
            <div ref={textRef} style={{ visibility: "hidden" }}>
              Rehan Jayawickerama
            </div>
          </Col>
        </Row>
      </main>
    </div>
  );
};
